<template>
  <aside data-component-name="SidebarMobile">
    <div class="top">
      <template v-if="!activeMenuItemKey">
        <h2>{{ $t('Menu') }}</h2>

        <button aria-label="close" @click="$emit('close')">
          <SvgClose />
        </button>
      </template>

      <template v-else>
        <span>
          <button aria-label="close" @click="setActiveMenuItem(null)">
            <SvgChevronLeft />
          </button>

          <h2>{{ $t(activeMenuItemKey) }}</h2>
        </span>

        <button aria-label="close" @click="$emit('close')">
          <SvgClose />
        </button>
      </template>
    </div>

    <nav v-if="!activeMenuItemKey" class="middle">
      <template v-for="item of MENU" :key="item.path">
        <TheHeaderItem
          :component="'children' in item ? 'button' : 'NuxtLink'"
          :to="localePathWithSlash(item.path)"
          mobile
          @click="handleClick(item)"
        >
          {{ $t(item.i18nKey) }}
        </TheHeaderItem>
      </template>
    </nav>

    <nav v-else class="middle">
      <template v-if="activeMenuItemKey === 'Language'">
        <NavItem
          v-for="{ code, name } of locales"
          :key="code"
          :to="switchLocalePathWithSlash(code)"
        >
          {{ name }}
        </NavItem>
      </template>

      <ul v-if="Array.isArray(activeMenuList)">
        <li v-for="child in activeMenuList" :key="child.i18nKey">
          <NavItem :to="localePathWithSlash(child.path)">
            {{ $t(child.i18nKey) }}
          </NavItem>
        </li>
      </ul>

      <ul v-for="submenu of Object.keys(activeMenuList)" v-else>
        <span>{{ $t(submenu) }}</span>
        <li v-for="child in activeMenuList[submenu]" :key="child.path">
          <NavItem :to="localePathWithSlash(child.path)">
            {{ $t(child.i18nKey) }}
          </NavItem>
        </li>
      </ul>

      <ul v-if="activeMenuItemKey === 'Resources'">
        <MenuResourcesPartial />
      </ul>
    </nav>

    <div v-if="!activeMenuItemKey" class="bottom">
      <Buttons.Link
        :to="localePathWithSlash(PATHS.HOW_TO_BUY.path)"
        fill="outline"
        accent="orange"
      >
        {{ $t("How_to_Buy") }}
      </Buttons.Link>

      <Buttons.Link
        :to="localePathWithSlash(PATHS.REQUEST_DEMO.path)"
        fill="solid"
        accent="orange"
      >
        {{ $t("Request_a_demo") }}
        <SvgArrowForward />
      </Buttons.Link>

      <TheHeaderItem  mobile @click="setActiveMenuItem('Language')">
        {{ locale }}
      </TheHeaderItem>
    </div>
  </aside>
</template>

<script setup lang="ts">
// types
import type { Item, ItemWithChildren } from '~/components/redesign/Menus/Menu.types';

// constants
import { PATHS } from '~/constants/nav-items';

// composable
import { useI18n } from '#i18n';
import useLocalePathWithSlash from '~/composables/useLocalePathWithSlash';

// utils
import { getNavItems } from './Menus/Menu.utils';

// components
import Buttons from '~/components/redesign/Buttons';
import TheHeaderItem from '~/components/redesign/TheHeaderItem.vue';
import NavItem from '~/components/redesign/NavItem.vue';
import MenuResourcesPartial from '~/components/redesign/Menus/MenuResourcesPartial.vue'

defineOptions({
  components: {
    TheHeaderItem,
    NavItem,
  },
});

type NavItem = Item | ItemWithChildren;

const router = useRouter();
const emit = defineEmits<{ close: [] }>();

const { locale, locales } = useI18n();
const switchLocalePathWithSlash = useSwitchLocaleWithSlash();

const localePathWithSlash = useLocalePathWithSlash();
const hasChildren = (item: NavItem) => 'children' in item;

const MENU = reactive<Array<NavItem>>(getNavItems());

const activeMenuItemKey = ref<string | null>(null);

const setActiveMenuItem = (value: string | null) => {
  activeMenuItemKey.value = value
};

const activeMenuList = computed<NavItem | []>(() => {
  const key = activeMenuItemKey.value;
  if (!key) return [];

  const parentItem = MENU.find(navItem => navItem.i18nKey === key);
  return parentItem && hasChildren(parentItem) ? parentItem.children : [];
});

const handleClick = (navItem: NavItem) => {
  hasChildren(navItem)
    ? setActiveMenuItem(navItem.i18nKey)
    : router.push(localePathWithSlash(navItem.path));
}
</script>

<style scoped lang="scss">
@import "$/variables/shadows";
@import "$/mixins/flex";
@import "$/mixins/typography";
@import "$/mixins/common";
@import "$/functions/token";

aside[data-component-name="SidebarMobile"] {
  background-color: token("surface");
  box-shadow: map-get($shadows, "Elevation 3");

  padding: 1.5rem;
  width: 20rem;

  @include flex-stretch-sb;
  flex-direction: column;

  @include mobile {
    width: 100%;
  }

  .top {
    @include flex-center-sb;

    h2 {
      @include subtitle-1;
    }

    button {
      svg {
        @include fixed-size(1.5rem)
      }
    }

    span {
      @include flex-center-sb;

      button {
        margin-right: 1rem;
      }
    }
  }

  nav.middle {
    height: 100%;
    margin: 3rem 0;
    overflow-y: auto;

    [data-component-name="Buttons.Link"] {
      display: block;

      &:first-of-type {
        margin-top: 1rem;
      }

      margin-bottom: 1rem;
    }

    ul {
      padding: 0;

      span {
        display: inline-block;
        padding: 1rem;
        @include subtitle-2;
      }
    }
  }

  .bottom {
    margin-top: auto;

    @include flex-stretch-sb;
    flex-direction: column;
    gap: 1rem;

    color: token('text-primary');

    [data-component-name="TheHeaderItem"] {
      :deep(span) {
        text-transform: uppercase;
      }
    }
  }

  [data-component-name="TheHeaderItem"] {
    color: token('text-primary');
    text-align: start;
  }
}
</style>
